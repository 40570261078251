import { createStore } from "vuex";
import ProductDataService from "@/service/ProductDataService";
import MainDataService from "@/service/MainDataService";
export default createStore({
  state: {
    attractions: [
      {
        name: "Усадьба Марьино",
        description: `В районе г. Тосно Ленинградской области, в деревне Андрианово, в 60 км от Санкт-Петербурга расположился один из самых пышных усадебных ансамблей России XIX века – родовое имение Строгановых-Голицыных усадьба Марьино - идеальное место для отдыха, романтических встреч и незабываемых торжеств.`,
        link: "https://usadbamaryino.ru/",
        image: "marino.jpg",
      },
      {
        name: "Саблинские водопады",
        description: `Саблинский водопад — одна из достопримечательностей Саблинского памятника природы в Ленинградской области. Живописный водопад располагается на реке Саблинка, на территории поселка Ульяновка.`,
        link: "https://www.tourister.ru/world/europe/russia/city/ulyanovka/waterfall/28772",
        image: "sablino_waterfall.jpg",
      },
      {
        name: "Церковь Троицы Живоначальной",
        description: `Церковь в честь Святой Троицы в д. Андрианово района г. Тосно, которая была возведена на территории усадьбы «Марьино». В церкви была усыпальница двух известных в России родов: графов Строгановых и князей Голицыных. 
        Церковь построена в неоготическом стиле (стиле английской псевдоготики), что придает ей заметную пышность и нарядность. Она является базиликой с одним помещением, массивными пилонами в наружных углах конструкции.`,
        link: "https://pravoslavie.wiki/russkaya-neogotika-cerkov-troicy-zhivonachalnoj-v-andrianovo.html",
        image: "church.jpg",
      },
      {
        name: "Тосненский водопад",
        description: `Тосненский водопад — достопримечательность одного из поселков Ленинградской области — Ульяновки. Название это природное чудо получило благодаря реке Тосне, в каньоне которой оно и расположено. Водопад часто сравнивают с Ниагарским из-за схожей формы, напоминающей подкову.`,
        link: "https://www.tourister.ru/world/europe/russia/city/ulyanovka/waterfall/28775",
        image: "tosno_waterfall.jpg",
      },
      {
        name: "Саблинские пещеры",
        description: `Саблинские пещеры — бывшие искусственно созданные штольни по добыче кварцевого песка, который использовался для изготовления стекла. В наши дни Саблинские пещеры (Левобережная) — это уникальный туристический объект, где проводятся увлекательные мероприятия для всей семьи, мастер-классы, познавательные программы, квесты, игры, тематические праздники, и даже новогодняя программа.`,
        link: "https://www.tourister.ru/world/europe/russia/city/ulyanovka/placeofinterest/43218",
        image: "sablino_cave.jpg",
      },
      {
        name: "Тосно теннис",
        description: `Саблинские пещеры — бывшие искусственно созданные штольни по добыче кварцевого песка, который использовался для изготовления стекла. В наши дни Саблинские пещеры (Левобережная) — это уникальный туристический объект, где проводятся увлекательные мероприятия для всей семьи, мастер-классы, познавательные программы, квесты, игры, тематические праздники, и даже новогодняя программа.`,
        link: "https://vk.com/tosnotennis",
        image: "tennis.webp",
      },
      {
        name: "Кинотеатр",
        description: `Саблинские пещеры — бывшие искусственно созданные штольни по добыче кварцевого песка, который использовался для изготовления стекла. В наши дни Саблинские пещеры (Левобережная) — это уникальный туристический объект, где проводятся увлекательные мероприятия для всей семьи, мастер-классы, познавательные программы, квесты, игры, тематические праздники, и даже новогодняя программа.`,
        link: "https://vk.link/kino_kosmonavt_3d",
        image: "kino.webp",
      },
      {
        name: "Тосненский музей",
        description: `Саблинские пещеры — бывшие искусственно созданные штольни по добыче кварцевого песка, который использовался для изготовления стекла. В наши дни Саблинские пещеры (Левобережная) — это уникальный туристический объект, где проводятся увлекательные мероприятия для всей семьи, мастер-классы, познавательные программы, квесты, игры, тематические праздники, и даже новогодняя программа.`,
        link: "https://www.lenoblmus.ru/museums/tosnenskiy-istoriko-kraevedcheskiy-muzey",
        image: "museum.jpg",
      },
      {
        name: "Пиццерия Баффо",
        description: ``,
        link: "https://baffo-pizza.ru/",
        image: "baffo.jpg",
      },
      {
        name: "Додо пицца",
        description: ``,
        link: "https://dodopizza.ru/tosno/lenina_47",
        image: "dodo.png",
      },
    ],
    /*     services:[{
      name
    }], */
    apartments: [
      {
        id: 1,
        name: "1-комнатная квартира в центре Тосно",
        minImage: "1_small.jpg",
        images: [
          "1.jpg",
          "2.jpg",
          "3.jpg",
          "4.jpg",
          "5.jpg",
          "6.jpg",
          "7.jpg",
          "8.jpg",
          "9.jpg",
          "10.jpg",
          "11.jpg",
          "12.jpg",
        ],
        price: 2500,
        phone_number: "+7 (906) 277 27-69",
        adress: "Ул. Советская 12",
        addresSrc: `https://yandex.ru/map-widget/v1/?um=constructor%3Abcd06a7b78b44e696be3acfd4d2e7f71b8f43d84c32de81bbb9a63e7dd3f3e3c&amp;source=constructor`,
        guestsCount: 3,
        size: 36,
        floor: 3,
        bedCount: 2,
        beds: ["диван, кресло"],
        description: `Вашему внимaнию пpeдлaгается в посутoчную аpенду отличная, уютная 1-комнатная квартира.
        B квapтиpе есть вcе нeoбxодимое для комфopтногo проживания:
        Оборудованная кухня, газовая плита для приготовлeния пищи, вся необходимая поcудa, стoлoвыe прибoры, холодильник, чайник, телевизор, фен, утюг, wi-fi,
        Санузел раздельный, выдаются чистые спальные принадлежности и полотенца.
        Вся инфраструктура в шаговой доступности. Рядом супермаркеты, кафе, рестораны, кинотеатр, дом культуры, теннис-клуб, парк, бассейн.
        До санкт-петербурга час езды. `,
        calls: "с 7:00 до 00:00",
        rules: {
          checkIn: "14:00",
          checkOut: "12:00",
          minimalPeriod: 1,
          kids: true,
          smoking: false,
          balcony: true,
          party: false,
          pets: false,
          drinks: false,
          deposit: 1000,
          passport: true,
        },
        amenities: {
          min: ["Wi-FI", "Телевизор", "Стиральная машина", "Фен"],
          full: [
            "Wi-FI",
            "Телевизор",
            "Стиральная машина",
            "Фен",
            "косметический ремонт",
            "чайник",
            "утюг",
            "полотенца",
            "постельное белье",
            "лоджия",
            "микроволновка",
          ],
          bath: [
            "Количество ванных комнат с туалетом  1",
            "полотенца",
            "фен",
            "душ",
          ],
          citchen: [
            "микроволновка",
            "посуда и принадлежности",
            "столовые приборы",
            "чайник",
            "холодильник",
            "кухонный гарнитур",
            "обеденный стол",
          ],
          room: ["телевизор Smart-TV", "Wi-Fi", "кресло", "кровать", "шкаф"],
          infrastructure: ["парк", "площадь", "лес", "кинотеатр", "теннис"],
        },
      },
      {
        id: 2,
        name: "1-комнатная квартира в центре Тосно",
        minImage: "1_small.jpg",
        images: [
          "1.jpg",
          "2.jpg",
          "3.jpg",
          "4.jpg",
          "5.jpg",
          "6.jpg",
          "7.jpg",
          "8.jpg",
          "9.jpg",
          "10.jpg",
          "11.jpg",
          "12.jpg",
        ],
        price: 2500,
        phone_number: "+7 (906) 277 27-69",
        adress: "Ул. Горького 2",
        addresSrc: `https://yandex.ru/map-widget/v1/?um=constructor%3A09588f661075f2ca8db9ff8a333065c87595857e57ffb742c2940fdd18380d68&amp;source=constructor`,
        guestsCount: 2,
        size: 25,
        floor: 5,
        bedCount: 1,
        beds: ["диван"],
        description: `Вашему внимaнию пpeдлaгается в посутoчную аpенду отличная, уютная 1-комнатная квартира.
        B квapтиpе есть вcе нeoбxодимое для комфopтногo проживания:
        Оборудованная кухня, электрическая плита для приготовлeния пищи, вся необходимая поcудa, стoлoвыe прибoры, холодильник, чайник, телевизор, фен, утюг, wi-fi,
        Санузел совмещённый, выдаются чистые спальные принадлежности и полотенца.
        Вся инфраструктура в шаговой доступности. Рядом супермаркеты, кафе, рестораны, кинотеатр, дом культуры, теннис-клуб, парк, бассейн.
        До санкт-петербурга час езды. `,
        calls: "с 7:00 до 00:00",
        rules: {
          checkIn: "14:00",
          checkOut: "12:00",
          minimalPeriod: 1,
          kids: true,
          smoking: false,
          balcony: false,
          party: false,
          pets: false,
          drinks: false,
          deposit: 1000,
          passport: true,
        },
        amenities: {
          min: ["Wi-FI", "Телевизор", "Стиральная машина", "Фен"],
          full: [
            "Wi-FI",
            "Телевизор",
            "Стиральная машина",
            "Фен",
            "косметический ремонт",
            "электрический чайник",
            "полотенца",
            "постельное белье",
            "микроволновка",
          ],
          bath: [
            "Количество ванных комнат с туалетом  1",
            "полотенца",
            "фен",
            "душ",
          ],
          citchen: [
            "микроволновка",
            "посуда и принадлежности",
            "столовые приборы",
            "электрический чайник",
            "холодильник",
            "кухонный гарнитур",
            "обеденный стол",
          ],
          room: ["телевизор Smart-TV", "Wi-Fi", "кровать", "шкаф"],
          infrastructure: ["парк", "площадь", "лес", "кинотеатр", "теннис"],
        },
      },
    ],
    privileges: [
      `Мы работаем только с надежными арендодателями, чтобы гарантировать вам качество и надежность жилья. 
      Все квартиры проходят проверку на соответствие нормам безопасности и комфорта, чтобы вы могли чувствовать себя как дома.`,
      `На нашем сайте вы найдете подробные описания квартир с указанием количества комнат, площади, наличия мебели и других удобств. 
      Мы стараемся предоставить максимум информации, чтобы вы смогли принять правильное решение при выборе жилья.`,
      `Мы стремимся предоставить нашим клиентам лучший сервис и поддержку на каждом этапе процесса аренды квартиры. 
      Наша команда профессионалов готова помочь вам ответить на все вопросы и решить любые проблемы, связанные с арендой жилья.`,
      `Мы работаем только с надежными арендодателями, чтобы гарантировать вам качество и надежность жилья. 
      Все квартиры проходят проверку на соответствие нормам безопасности и комфорта, чтобы вы могли чувствовать себя как дома.`,
    ],
    mainPagePhoneNumber: '+7 (906) 277 27-69'
  },
  getters: {
    getApartmentByID: (state) => (id) => {
      return state.apartments.find((el) => el.id == id);
    },
  },
  mutations: {
    setAllPrices(state, prices) {
      for (let i = 0; i < prices.length; i++) {
        let apart = state.apartments.find(
          (el) => el.id === Number(prices[i].id)
        );
        apart.price = prices[i].price;
        apart.phone_number = prices[i].phone_number;
      }
    },
    setPrice(state, price) {
      let apart = state.apartments.find((el) => el.id === price.id);
      apart.price = price.price;
      apart.phone_number = price.phone_number;
    },
    setMainPhoneNumber(state, number) {
      state.mainPagePhoneNumber = number;
    },
  },
  actions: {
    async getPrices() {
      let items = [];
      await ProductDataService.getAll().then((res) => {
        items = res.data;
        this.commit("setAllPrices", items);
      });
    },
    async setInfo({ commit }, itemInfo) {
      await ProductDataService.update(itemInfo).then(() => {
        commit("setPrice", itemInfo);
      });
    },
    async getMainPhoneNumber({ commit }) {
      await MainDataService.getItem().then((res) => {
        commit("setMainPhoneNumber", res.data[0].phone_number);
      });
    },
    async setMainPhoneNumber({ commit }, itemInfo) {
      await MainDataService.update(itemInfo).then(() => {
        commit("setMainPhoneNumber", itemInfo.phone_number);
      });
    },
  },
  modules: {},
});
