class serverConfig {
    constructor() {
        /* this.serverRoot = 'http://localhost/aparts-full/' */
        this.serverRoot = 'https://xn----ytbbbabbbbvfgei6d_xn--p1ai.regruproxy.ru/'
    }

    getRoot() {
        return this.serverRoot
    }
}
export default new serverConfig()