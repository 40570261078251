import axios from 'axios'
import serverRoot from "@/service/serverRoot.js";

let path = serverRoot.getRoot();
const USER_API_URL = path + 'back-end/operations/product'

class ProductDataService {

    getAll() {
        return axios.get(`${USER_API_URL}/read.php`);
    }

    update(item) {

        return axios.post(`${USER_API_URL}/update.php`, item);
    }

}

export default new ProductDataService()