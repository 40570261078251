
<template>
  <div class="admin">
    <ul>
      <li v-for="apartment in apartments" :key="apartment">
        <AdminApartmentCard :itemInfo="apartment"></AdminApartmentCard>
      </li>
    </ul>

    <div class="control-main">
      <h2>Номер телефона на главной странице:</h2>
      <template v-if="!isPhoneChanged">
        <p>{{ mainPagePhoneNumber }}</p>
      </template>
      <template v-else>
        <label for="">
          <input type="text" name="" id="" v-model="phone_number">
        </label>
      </template>
      <div class="control-btn">
        <template v-if="!isPhoneChanged">
          <button @click="changeMainNumber">Изменить номер телефона</button>
        </template>
        <template v-else>
          <button @click="saveMainNumber">Сохранить номер телефона</button>
        </template>
      </div>

    </div>


    <div class="sign-out">
      <button @click="signOut">Выйти из учетной записи</button>
    </div>
  </div>
</template>

<script>
import UserDataService from "@/service/UserDataService";
import AdminApartmentCard from '@/components/admin/AdminApartmentCard.vue'
import { mapState } from 'vuex'
export default {
  name: 'AdminView',
  components: {
    AdminApartmentCard
  },
  data() {
    return {
      isPhoneChanged: false,
      phone_number: ''
      /*       apartments: this.$store.state.apartments */
    }
  },
  computed: {
    // смешиваем результат mapState с внешним объектом
    ...mapState({
      apartments: state => state.apartments,
      mainPagePhoneNumber: state => state.mainPagePhoneNumber
      // ...
    })
  },
  watch: {
    apartments() {
    },

  },
  methods: {
    changeMainNumber() {
      this.phone_number = this.mainPagePhoneNumber;
      this.isPhoneChanged = true;
    },
    saveMainNumber(){
      this.isPhoneChanged = false;
      this.$store.dispatch('setMainPhoneNumber',{
        id: 1,
        phone_number: this.phone_number
      });
    },
    signOut() {
      UserDataService.signOut().then(() => {
        this.$router.push('/');
      })
    }
  },

  created() {
    UserDataService.checkSession().then((res) => {
      if (res.data == 'session unset') {
        this.$router.push('/');
      }
    });
  },
  mounted() {
    /* this.$store.state.activaPage = 'gallery'; */
    window.scroll({
      left: 0, // до какого количества пикселей прокрутить вправо
      top: 0, // до какого количество пикселей прокрутить вниз
      behavior: 'smooth' // определяет плавность прокрутки: 'auto' - мгновенно (по умолчанию), 'smooth' - плавно
    });

  }
}
</script>
<style scoped>
.control-main {
  width: calc(70% - 40px);
  background-color: white;
  padding: 20px;
  border-radius: 10px;

}

.control-main p {
  font-size: 1.3rem;
  padding: 20px;
  margin-bottom: 5px;
}

.control-main input {
  font-size: 1.3rem;
  margin: 20px;
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  border: 1px solid rgb(239, 239, 239);
}

.control-btn {
  margin-top: 15px;
  margin-bottom: 15px;
}

.control-btn button {
  min-width: 150px;
  border: 1px solid rgb(238, 238, 238);
  background-color: rgb(247, 247, 247);
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.control-btn button:hover {
  color: white;
  background-color: rgb(9, 4, 86);
}

.sign-out {
  margin-top: 15px;
  margin-bottom: 15px;
}

.sign-out button {
  min-width: 180px;
  border: 1px solid rgb(254, 248, 248);
  background-color: rgb(180, 12, 12);
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  color: white;
}

.sign-out button:hover {
  background-color: rgb(9, 4, 86);
}

.admin {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: rgb(243, 243, 243);
}

.admin ul {
  width: 70%;
}
</style>
